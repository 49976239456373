<template>
  <div class="site-detail">
    <!-- 轮播 -->
    <div class="swiper-wrap site-detail-swiper">
      <home-swiper :images="detail.images"></home-swiper>
    </div>

    <!-- 场所信息 -->
    <div class="bgfff dfc message-wrap">
      <div class="dfc main-start">
        <div class="fs32 fb fc333">{{ detail.name }}</div>
        <div class="dfr cross-center flex-wrap mt10">
          <div
            v-for="(item, index) in detail.meeting_space_tags"
            :key="index"
            class="message-desc mr12 mt6"
          >
            <div class="fs20 fc666">{{ item.name }}</div>
          </div>
        </div>
        <div class="dfr cross-end message-price mt28">
          <div class="fs24 mb2">￥</div>
          <div class="fs36">{{ detail.per_capita }}</div>
          <div class="fs24 mb4">/人</div>
        </div>
      </div>
      <div class="dfc main-end">
        <div class="dfr cross-center main-between message-post">
          <div class="dfr cross-center main-start">
            <div class="start-img fs0 mr20">
              <img
                src="../assets/images/siteDetail-icon.png"
                alt=""
                class="w100 h100"
              />
            </div>
            <div class="fs28 fc666">{{ detail.linkman }}</div>
          </div>
          <a class="end-img fs0" :href="`tel:${detail.phone}`">
            <img src="../assets/images/phone.png" alt="" class="w100 h100" />
          </a>
        </div>
        <div class="dfr cross-center main-between message-address">
          <div class="dfr main-start cross-center">
            <div class="start-img fs0 mr20">
              <img
                src="../assets/images/siteDetail-address.png"
                alt=""
                class="w100 h100"
              />
            </div>
            <div @click="showMap = true" class="address">
              {{ detail.address }}
            </div>
          </div>
          <div class="end-img fs0">
            <img
              src="../assets/images/rightArrow.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt20 bgfff bottom-wrap">
      <!-- 场所特色 -->
      <app-title title="场所特色"></app-title>
      <div class="feature-box mt28 fs28 fc666">
        {{ detail.recommend_words }}
      </div>

      <!-- 使用记录 -->
      <div v-if="employ.length">
        <app-title title="使用记录" class="mt60"></app-title>
        <div class="dfr flex-wrap mt8">
          <div
            v-for="(item, index) in employ"
            :key="index"
            class="employ-item bgfff mr18 mt20"
          >
            <div class="avatar-box por">
              <div class="avatar w100 h100 fs0">
                <img :src="item.host_user.avatar" alt="" class="w100 h100" />
              </div>
              <div class="tag-box fs0 poa" v-if="item.host_user.is_auth == 1">
                <img
                  src="../assets/images/home-emblem.png"
                  alt=""
                  class="w100 h100"
                />
              </div>
            </div>
            <div class="mt20 fs32 fb fc333 dfr main-center">
              {{ item.host_user.name }}
            </div>
            <div class="dfr cross-end main-center mt8">
              <div class="fs24 fc666 mr8">使用记录</div>
              <div class="fs28 fb num-box">{{ item.count }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 地图展示 -->
    <van-popup v-model="showMap" position="bottom" round>
      <div class="map-wrap">
        <baidu-map
          v-if="center"
          class="w100 h100"
          :center="center"
          :zoom="zoom"
          @ready="mapHandler"
        >
          <bm-marker
            :position="center"
            :dragging="true"
            @click="infoWindowOpen"
            animation="BMAP_ANIMATION_BOUNCE"
          >
            <bm-info-window
              :show="addressShow"
              @close="infoWindowClose"
              @open="infoWindowOpen"
              >{{ detail.name }} <br />
              {{ detail.address }}</bm-info-window
            >
          </bm-marker>
        </baidu-map>
        <empty v-else content="暂不支持自定义地址显示"></empty>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      showMap: false,
      addressShow: true,
      center: null,
      zoom: 18,
      spaceId: "",
      detail: {},
      employ: []
    };
  },
  created() {
    this.$title("场所详情");
    window.scrollTo(0, 0);
    this.spaceId = this.$route.query.id;
    this.initPage();
  },
  mounted() {},
  methods: {
    mapHandler() {},
    infoWindowClose() {
      this.addressShow = false;
    },
    infoWindowOpen() {
      this.addressShow = true;
    },
    initPage() {
      this.getSiteDetail();
    },
    // 获取场所详情
    async getSiteDetail() {
      let space_id = parseInt(this.spaceId);
      const resp = await this.$API
        .get("siteDetail", { space_id })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.detail = resp.data.list[0];
        let lnglat = null;
        if (this.detail && this.detail.longitude && this.detail.latitude) {
          lnglat = {
            lng: this.detail.longitude,
            lat: this.detail.latitude
          };
        }

        this.center = lnglat;
        this.employ = resp.data.meeting_user;
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.map-wrap {
  height: 90vh;
  width: 100vw;
}
.site-detail {
  height: 100vh;
  background: #f7f7f7;
  .swiper-wrap {
    width: 7.5rem;
    height: 5.92rem;
  }
  .message-wrap {
    padding: 0.28rem 0.36rem 0rem 0.36rem;
    .message-desc {
      background: #f6f6f6;
      border-radius: 0.04rem;
      padding: 0.08rem 0.16rem;
    }
    .message-price {
      color: #cb9025;
    }
    .message-post {
      height: 0.88rem;
      border-bottom: 1px solid #eee;
      .end-img {
        width: 0.28rem;
        height: 0.32rem;
      }
    }
    .message-address {
      height: 0.88rem;
      .address {
        width: 5.84rem;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .end-img {
        width: 0.1rem;
        height: 0.16rem;
      }
    }
    .start-img {
      width: 0.28rem;
      height: 0.28rem;
    }
  }
  .bottom-wrap {
    padding: 0.36rem;
    .feature-box {
      width: 6.78rem;
      background: #f8f9fb;
      border-radius: 0.12rem;
      padding: 0.28rem;
    }
    .employ-item {
      width: 2.14rem;
      height: 3.08rem;
      border-radius: 0.12rem;
      box-shadow: 0 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
      .avatar-box {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0.4rem auto 0;
        .avatar {
          border-radius: 50%;
          overflow: hidden;
          object-fit: cover;
        }
        .tag-box {
          width: 0.32rem;
          height: 0.32rem;
          bottom: 0;
          right: 0;
        }
      }
      .num-box {
        color: #cb9025;
      }
    }
    .employ-item:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
